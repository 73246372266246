import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import { useAuth } from '@src/auth-wrapper';

import { IManagementContractPayload, IManagementContract, IManagementContractContext } from './types';

export const ManagementContractContext = createContext({} as IManagementContractContext);

interface Provider {
  children: React.ReactNode;
}

const ManagementContractProvider: React.FC<Provider> = ({ children }) => {
  const isDevMode = window.location.origin.includes('localhost');
  const currentDomain = window.location.origin;
  const bffPort = '5001';
  const localHost = `${currentDomain.replace(/:\d+/, `:${bffPort}`)}`;
  const virtualHost = 'https://teste-bff.vercel.app';
  const bffBaseURL = `${!isDevMode ? localHost : virtualHost}/management-contract`;
  const bffApiClient = axios.create({
    baseURL: bffBaseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  function getManagementContractDraft(userId: string, proposalId: string): AxiosPromise {
    return bffApiClient.get('/draft', {
      params: {
        user_id: userId,
        proposal_id: proposalId,
      },
    });
  }
  function getManagementContracts(product: string, status: string, proposalId?: string): AxiosPromise {
    return bffApiClient.get('/all', {
      params: {
        product: product,
        status: status,
        proposal_id: proposalId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  function createManagementContract(contract: IManagementContractPayload): AxiosPromise {
    return bffApiClient.post('/', contract);
  }

  function createManagementContractDraft(draft: IManagementContractPayload): AxiosPromise {
    return bffApiClient.post('/draft', draft, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function updateManagementContractDraft(draft: IManagementContract): AxiosPromise {
    return bffApiClient.post(`/draft/${draft.id}`, draft, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function publishManagementContractDraft(contract: IManagementContract): AxiosPromise {
    return bffApiClient.post('/publish', contract, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return (
    <ManagementContractContext.Provider
      value={{
        createManagementContractDraft,
        updateManagementContractDraft,
        publishManagementContractDraft,
        getManagementContractDraft,
        getManagementContracts,
        createManagementContract,
      }}
    >
      {children}
    </ManagementContractContext.Provider>
  );
};

export default ManagementContractProvider;
