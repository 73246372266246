import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { parseProductOneSimulationResults } from '@contexts/product-one/parser';

import useProductOne from '@hooks/use-product-one';
import useWhiteLabel from '@hooks/use-white-label';

import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';

import { ManagementSectionProposalSection } from '@components/organisms/new-proposal/management';
import AboutSectionProposalContent from '@components/organisms/new-proposal/about';
import { FeasibilitySectionProposalSection } from '@components/organisms/new-proposal/feasibility';
import { NextStepSectionProposalSection } from '@components/organisms/new-proposal/next-steps';
import { EnergyTypeComparasionSection } from '@components/organisms/new-proposal/energy-comparasion';
import LoadingPage from '@components/templates/loading-page';

import ErrorUpdateTheProposal from '@pages/error-update-the-proposal';
import './styles.css';

function ProductOneProposalSectionCore() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { proposal, getProductOneProposalById, isValidProposalProductOne } = useProductOne();

  const simulationResults = proposal && parseProductOneSimulationResults(proposal);
  const { isLoading, getWhiteLabelSupplierById, setIsWhiteLabelProposal } = useWhiteLabel();

  const getProposal = async (id: string) => {
    await getProductOneProposalById(id)
      .then(async (proposal) => {
        const supplierId = proposal?.inputs?.whiteLabelSupplierId;
        if (supplierId) {
          setIsWhiteLabelProposal(true);
          await getWhiteLabelSupplierById(supplierId);
        }
      })
      .catch(() => navigate(SIMULATION_NOT_FOUND_PATH));
  };

  React.useEffect(() => {
    if (id) getProposal(id);
  }, [id]);
  if (!isLoading && proposal) {
    if (isValidProposalProductOne) {
      return (
        <section className="section-core-proposal">
          <AboutSectionProposalContent />
          <FeasibilitySectionProposalSection />
          <ManagementSectionProposalSection simulationResults={simulationResults && simulationResults[0]} />
          <EnergyTypeComparasionSection />
          <NextStepSectionProposalSection />
        </section>
      );
    } else {
      return <ErrorUpdateTheProposal />;
    }
  } else {
    return <LoadingPage />;
  }
}

export default ProductOneProposalSectionCore;
