import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { getCalculatorName, parseStringToBoolean } from '@utils/common';

import eventTracker from '@services/eventTracking';

import { RadioFormField } from '@components/atoms/form/fields/radio-form-field';
import { IGeneralFormSelectableInputsOptions } from '@components/atoms/form/types';

import { IWhiteLabelSupplierPayload, PricesTypesEnum } from '@schemas/white-label/types';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import useWhiteLabel from '@hooks/use-white-label';
import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';

export const WhiteLabelSupplierFormPriceSection = (): JSX.Element => {
  const { setValue } = useFormContext<IWhiteLabelSupplierPayload>();
  const isUpdateWhiteLabelSupplierPath = window.location.pathname.includes('editar');
  const { whiteLabelSupplier } = useWhiteLabel();
  const aux__usePrices = useWatch({ name: 'aux__usePrices' }) ?? false;
  const [isInputActive, setIsInputActive] = React.useState<boolean>(aux__usePrices);
  const numericFields = Object.keys({
    management: whiteLabelSupplier?.prices?.management,
    migration: whiteLabelSupplier?.prices?.migration,
  });
  const defaultBooleanInputOptions: Array<IGeneralFormSelectableInputsOptions> = [
    {
      id: 'true',
      value: 'true',
      optionLabel: 'Sim',
      defaultChecked: isInputActive,
    },
    {
      id: 'false',
      value: 'false',
      optionLabel: 'Não',
      defaultChecked: !isInputActive,
    },
  ];

  return (
    <ContractSectionContainer>
      <div className="grid grid-cols-3 col-span-full min-w-full">
        <ContractTitleAndSubtitleSubsection title="Preços" subtitle="Informações financeiras da fornecedora." />
        <div className="col-span-2 grid grid-cols-2">
          <div className="col-span-2 grid grid-cols-2 gap-6 items-baseline">
            <RadioFormField<IWhiteLabelSupplierPayload>
              label="Há algum custo associado à gestão?"
              field="aux__usePrices"
              inputOptions={defaultBooleanInputOptions}
              options={{
                onChange: (event) => {
                  const parsedChoosedOption = parseStringToBoolean(event.target.value);
                  if (!parsedChoosedOption) {
                    setValue('prices', undefined);
                  }
                  setValue('aux__usePrices', parsedChoosedOption);
                  setIsInputActive(parsedChoosedOption ?? false);
                  parsedChoosedOption &&
                    eventTracker.trackEvent(eventLabels.INSERT_MANUAL_PRICES_CHECKED, {
                      calculator: getCalculatorName(location.href),
                    });
                },
              }}
            />
          </div>
          {isInputActive && (
            <div className="grid grid-cols-2 col-span-2 gap-6 mt-6 w-full">
              {numericFields.map((price) => (
                <NumericFormField<IWhiteLabelSupplierPayload>
                  key={`white-label-price-${price}`}
                  id={`prices.${price as keyof typeof PricesTypesEnum}`}
                  field={`prices.${price as keyof typeof PricesTypesEnum}`}
                  label={PricesTypesEnum[price as keyof typeof PricesTypesEnum]}
                  formatProps={{
                    prefix: 'R$',
                    thousandSeparator: '.',
                    decimalScale: 2,
                    decimalSeparator: ',',
                    fixedDecimalScale: true,
                    allowNegative: false,
                  }}
                  options={{
                    required:
                      isInputActive && isUpdateWhiteLabelSupplierPath
                        ? { value: true, message: 'Campo é obrigatório' }
                        : false,
                  }}
                />
              ))}
              <NumericFormField<IWhiteLabelSupplierPayload>
                id="prices.discount"
                field="prices.discount"
                label="Desconto"
                formatProps={{
                  suffix: '%',
                  thousandSeparator: '.',
                  decimalScale: 0,
                  decimalSeparator: ',',
                  fixedDecimalScale: true,
                  allowNegative: false,
                }}
                options={{
                  required:
                    isInputActive && isUpdateWhiteLabelSupplierPath
                      ? { value: true, message: 'Campo é obrigatório' }
                      : false,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </ContractSectionContainer>
  );
};
