import React from 'react';
import numeral from 'numeral';

import { IContractedDemandSimulatorResult } from '@pages/contracted-demand/simulator/contracted-demand-simulator-result';

import ContractedDemandSimulatorResultItem from '@components/molecules/contracted-demand/simulator/contracted-demand-simulator-result-item';

type IContractedDemandSimulatorResultCore = IContractedDemandSimulatorResult;

const ContractedDemandSimulatorResultCore = ({ simulatorResult }: IContractedDemandSimulatorResultCore) => {
  const simulationResultData = simulatorResult?.data?.data;
  return (
    <div>
      {simulationResultData?.isWorthwhile ? (
        <h2 className="font-bold text-green-600 text-3xl mb-7">Encontramos Economia na Demanda Contratada!</h2>
      ) : (
        <h2 className="font-bold text-pink-600 text-3xl mb-7">Não encontramos Economia na Demanda Contratada!</h2>
      )}
      <div className="mt-5 border-t border-gray-200 mb-7">
        <dl className="sm:divide-y sm:divide-gray-200">
          <ContractedDemandSimulatorResultItem
            label="Nova Demanda Fora Ponta"
            value={simulationResultData?.newAdjustedDemandOffPeak}
          />
          <ContractedDemandSimulatorResultItem
            label="Economia Anual Fora Ponta"
            value={numeral(simulationResultData?.annualEconomyOffPeak).format('$ 0,0.00')}
          />
          {simulationResultData?.newAdjustedDemandPeak && (
            <ContractedDemandSimulatorResultItem
              label="Nova Demanda Ponta"
              value={simulationResultData?.newAdjustedDemandPeak}
            />
          )}
          {simulationResultData?.annualEconomyPeak && (
            <ContractedDemandSimulatorResultItem
              label="Economia Anual Ponta"
              value={numeral(simulationResultData?.annualEconomyPeak).format('$ 0,0.00')}
            />
          )}
          <ContractedDemandSimulatorResultItem
            label="Valor anual Clarke"
            value={numeral(simulationResultData?.annualClarkeValue).format('$ 0,0.00')}
          />
        </dl>
      </div>
    </div>
  );
};

export default ContractedDemandSimulatorResultCore;
