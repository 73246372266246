import { IProductTwoEnergyTypeComparison } from '@schemas/product-two/types';
import { IProductOneEnergyTypeComparison } from '@contexts/product-one/types';
import { EnergyComparisonTable } from '@components/atoms/detailedAnalysisGC/energyComparisonTable';
import { getEnergyTypeComparison } from './helper';
import { DropdownSection } from './dropdonw';

export function GenerateEnergyTypeComparisonDataAnalysis({
  proposalData,
}: {
  proposalData: IProductTwoEnergyTypeComparison | IProductOneEnergyTypeComparison;
}) {
  const energyTypeComparison = getEnergyTypeComparison(proposalData);

  return (
    <>
      <DropdownSection
        title={`Comparativo de energia i50 x i100`}
        iconName="CalculatorIcon"
        iconColor="text-primary-70"
        className="bg-slate-50 border-brand-gray-10 border rounded-t-lg"
        titleBackgroundColor="brand-gray-10"
        titleBackgroundColorOnHover="brand-gray-20"
      >
        <EnergyComparisonTable data={energyTypeComparison} />
      </DropdownSection>
    </>
  );
}
