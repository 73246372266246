import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';

import {
  LOW_TENSION_OPTING_INITIAL_EXPENSES,
  LOW_TENSION_STANDARD_INITIAL_EXPENSES,
  TARIFFS_INDEXER,
  TARIFF,
} from '@utils/constants';

import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { BuildManagementSimulatorFields, BuildRetailSimulatorFields } from './helper';
import { calculateUnitSupplyStartDate } from '@components/molecules/form/simulator/default-values-getters';

interface ISimulatorUnitGeneralSubsection extends ISimulatorFormUnitSection {
  isLowTension: boolean;
  isRetailSimulator: boolean;
}
export function SimulatorUnitGeneralSubsection({
  unitNum,
  fieldsController,
  isLowTension = false,
  isRetailSimulator,
}: ISimulatorUnitGeneralSubsection) {
  const { setValue, trigger } = useFormContext();
  const { user } = useAuth();
  const tariffInput = useWatch({ name: `units.${unitNum}.tariff` }) as keyof typeof TARIFFS_INDEXER;

  const isAlredyAtAclInitialValue = useWatch({ name: `units.${unitNum}.isAlredyAtAcl` });
  const [isAlredyAtAcl, setIsAlredyAtACL] = React.useState<boolean>(isAlredyAtAclInitialValue ?? false);

  useEffect(() => {
    const initialExpensesValueByTariff = getInitialExpensesValueByTariff(tariffInput);
    initialExpensesValueByTariff && setValue(`units.${unitNum}.initialExpenses`, initialExpensesValueByTariff);
  }, [tariffInput]);

  const getInitialExpensesValueByTariff = (tariffInput: keyof typeof TARIFFS_INDEXER) => {
    if (tariffInput === TARIFF.B3_OPTING) {
      return LOW_TENSION_OPTING_INITIAL_EXPENSES;
    } else if (tariffInput === TARIFF.B3_STANDARD) {
      return LOW_TENSION_STANDARD_INITIAL_EXPENSES;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setValue(`units.${unitNum}.supplyStartDate`, calculateUnitSupplyStartDate(false, isAlredyAtAcl));
    setValue(`units.${unitNum}.isAlredyAtAcl`, isAlredyAtAcl);
  }, [isAlredyAtAcl]);

  calculateUnitSupplyStartDate(true, false);

  return !isRetailSimulator ? (
    <BuildManagementSimulatorFields
      {...{
        unitNum,
        fieldsController,
        isAlredyAtAcl,
        setIsAlredyAtACL,
        trigger,
        user,
      }}
    />
  ) : (
    <BuildRetailSimulatorFields
      {...{
        unitNum,
        fieldsController,
        isAlredyAtAcl,
        setIsAlredyAtACL,
        trigger,
        user,
        isLowTension,
      }}
    />
  );
}
