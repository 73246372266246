import React from 'react';

import MainRouter from '@routers/main-router';
import AuthWrapper from '@src/auth-wrapper';
import './numeral';

function App() {
  return (
    <AuthWrapper>
      <MainRouter />
    </AuthWrapper>
  );
}

export default App;
