import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import { transformObjectKeysToSnakeCase } from '@utils/common';

import { ICommercialDealerComission, IResponseBase, IUpdateSellerPayload } from '@schemas/types';

import { isDateAfterTarget } from '@contexts/helper';
import { IProductTwoSimulatorInputs, IProductTwoContext, IProductTwoProposal } from '../../schemas/product-two/types';
import { parseProductTwoProposal, parseProductTwoSimulationPayload } from './parser';
import { useAuth } from '@auth-wrapper';

export const ProductTwoContext = createContext({} as IProductTwoContext);

interface Provider {
  children: React.ReactNode;
}

const ProductTwoProvider: React.FC<Provider> = ({ children }) => {
  const isDevMode = window.location.origin.includes('localhost');
  const currentDomain = window.location.origin;
  const bffPort = '5001';
  const localHost = `${currentDomain.replace(/:\d+/, `:${bffPort}`)}`;
  const virtualHost = 'https://teste-bff.vercel.app';
  const bffBaseURL = `${!isDevMode ? localHost : virtualHost}/product-two`;

  const bffApiClient = axios.create({
    baseURL: bffBaseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const [proposal, setProposal] = React.useState<IProductTwoProposal>({} as IProductTwoProposal);
  const [isLoadingProductTwo, setIsLoadingProductTwo] = React.useState<boolean>(false);
  const [isValidProposalProductTwo, setIisValidProposalProductTwo] = React.useState<boolean>(false);

  async function createProductTwoSimulation(
    rawInputs: IProductTwoSimulatorInputs,
    simulationData: IProductTwoSimulatorInputs,
  ): AxiosPromise {
    const simulationPayload = parseProductTwoSimulationPayload(simulationData);
    return bffApiClient.post('/simulation', { ...simulationPayload, raw_inputs: rawInputs });
  }

  function getProductTwoProposals(leadId?: string, sellerId?: string): AxiosPromise {
    return bffApiClient.get('/proposals', {
      params: {
        lead_id: leadId,
        seller_id: sellerId,
      },
    });
  }

  function getProductTwoProposalById(proposalId?: string): AxiosPromise {
    bffApiClient
      .get(`/proposals/${proposalId}`)
      .then((response) => {
        const parsedProposal: IProductTwoProposal = parseProductTwoProposal(response.data.data);
        setProposal(parsedProposal);

        const isProposalAfterTargetDate = isDateAfterTarget(parsedProposal?.createdAt, '2024-12-20T12:00:00');
        setIsLoadingProductTwo(true);
        setIisValidProposalProductTwo(isProposalAfterTargetDate);
      })
      .catch(() => {
        setIsLoadingProductTwo(false);
      })
      .finally(() => {
        setIsLoadingProductTwo(false);
      });
    return bffApiClient.get(`proposals/${proposalId}`);
  }

  function deleteProductTwoProposalsHandler(leadId: string, proposalsIds: Array<string>): AxiosPromise<IResponseBase> {
    return bffApiClient.post(`/proposals/delete`, { lead_id: leadId, proposals_ids: proposalsIds });
  }

  function deleteProductTwoAllProposalsByLeadIdHandler(leadId: string): AxiosPromise<IResponseBase> {
    return bffApiClient.post(`/proposals/delete/all`, { lead_id: leadId }, { params: { all_from_lead: true } });
  }
  function updateSellerOfProductTwoProposal(updateSellerData: IUpdateSellerPayload): AxiosPromise<IResponseBase> {
    return bffApiClient.post(`/proposals/update-seller/${updateSellerData.proposalId}`, {
      seller: {
        id: updateSellerData.seller.id,
        name: updateSellerData.seller.name,
        picture_url: updateSellerData.seller.pictureUrl,
      },
      lead_id: updateSellerData.leadId,
    });
  }

  function updateProductTwoProposalCommercialDealerComission(
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ): AxiosPromise {
    const commercialDealerComissionPayload = transformObjectKeysToSnakeCase(commercialDealerComissionData);
    return bffApiClient.post(`/update-proposal-comission/${proposalId}`, commercialDealerComissionPayload);
  }

  return (
    <ProductTwoContext.Provider
      value={{
        proposal,
        isLoadingProductTwo,
        isValidProposalProductTwo,
        createProductTwoSimulation,
        getProductTwoProposalById,
        getProductTwoProposals,
        deleteProductTwoProposalsHandler,
        deleteProductTwoAllProposalsByLeadIdHandler,
        updateSellerOfProductTwoProposal,
        updateProductTwoProposalCommercialDealerComission,
      }}
    >
      {children}
    </ProductTwoContext.Provider>
  );
};

export default ProductTwoProvider;
