import React, { useEffect } from 'react';
import useWhiteLabel from '@hooks/use-white-label';
import { applyWhiteLabelColors, cardContentStyles, getVariantClassForTypeAndIndex } from './helper';
import './styles.css';

export interface CardContentProps {
  title: string;
  label: string;
  index?: number;
  type?: 'standard' | 'specialColor' | 'description' | 'variant';
  whiteLabelSupplierStyles?: Record<string, any>;
}

const CardContent: React.FC<CardContentProps> = ({ title, label, index = 0, type = 'standard' }) => {
  const variantClass = getVariantClassForTypeAndIndex(type, index);
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();

  const {
    container: cardWrapperStyle,
    title: CardTitleStyle,
    titleWhiteLabel: CardTitleWhiteLabelStyle,
    label: CardLabelStyle,
  } = cardContentStyles({ type, variantClass });

  useEffect(() => {
    applyWhiteLabelColors({ ...whiteLabelSupplier?.colors });
  }, [isWhiteLabelProposal, whiteLabelSupplier]);

  return (
    <div className={cardWrapperStyle()}>
      <div className={isWhiteLabelProposal ? CardTitleWhiteLabelStyle() : CardTitleStyle()}>{title}</div>
      <div className="card-content-label-wrapper">
        <div className={CardLabelStyle()} dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    </div>
  );
};

export default CardContent;
