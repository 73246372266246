import numeral from 'numeral';

import { transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';
import { getParsedCustomFee, parseEnergyPrices } from '@contexts/parser';
import { IGeneralTitleAndValuePair } from '@schemas/types';

import {
  IProductTwoSimulationExtendedResult,
  IProductTwoBestSimulationResult,
  IProductTwoSimulatorInputs,
  IProductTwoProposal,
  IProductTwoSimulationUnitExtendedResult,
} from '../../schemas/product-two/types';
import { TARIFF } from '@utils/constants';
import { IUnitReport } from '@pages/detailed-analysis/types';

function parseProductTwoManualPrices(manualPrices: Record<any, any>): void {
  Object.keys(manualPrices).forEach((key) => {
    const value = manualPrices[key];
    if (isNaN(Number(key)) || value === null || key.length !== 4) {
      delete manualPrices[key];
    }
  });
}
export const removeInvalidData = (payload: IProductTwoSimulatorInputs) => {
  const units = payload.units;

  units?.map((unit) => {
    const tariff = unit.tariff;
    if (tariff !== TARIFF.B3_OPTING && tariff !== TARIFF.B3_STANDARD) {
      delete unit.operationHoursRange;
    }
  });
};
export const parseProductTwoSimulationPayload = (simulationData: IProductTwoSimulatorInputs): Record<string, any> => {
  const manualAclPrice = simulationData.manualAclPrice;
  const targetYear = simulationData.targetYear;
  removeInvalidData(simulationData);
  manualAclPrice && targetYear && parseProductTwoManualPrices((manualAclPrice as Record<string, any>)[targetYear]);

  const simulationDataWithAdvancedFinancialData: IProductTwoSimulatorInputs = {
    ...simulationData,
    customFee: getParsedCustomFee(simulationData),
    customTaxes: undefined,
    productType: 'ACL_RETAILER',
    manualAclPrice: manualAclPrice,
    calculationType: 'month_to_month',
  };
  return transformObjectKeysToSnakeCase(simulationDataWithAdvancedFinancialData);
};

export const parseProductTwoProposal = (proposal: Record<string, any>): IProductTwoProposal => {
  return transformObjectKeysToCamelCase(proposal) as IProductTwoProposal;
};

export const parseProductTwoProposals = (proposals: Record<string, any>): IProductTwoProposal => {
  return transformObjectKeysToCamelCase(proposals) as IProductTwoProposal;
};

const parseProductTwoSimulationPremises = (
  energyType: string,
  managementPrice?: number,
  supplyStartDate?: string,
  targetYear?: number,
  averageConsumption?: number,
): Array<IGeneralTitleAndValuePair> => {
  const premises: Array<IGeneralTitleAndValuePair> = [
    {
      title: 'Consumo Médio',
      value: (averageConsumption && numeral(averageConsumption).format('0.0000').concat(' MWm')) ?? '-',
    },
    {
      title: 'Data de Migração',
      value: supplyStartDate,
    },
    {
      title: 'Duração',
      value: `${targetYear} ano(s)`,
    },
    {
      title: 'Energia',
      value: energyType === 'INCENTIVIZED_100' ? 'Incentivada 100%' : 'Incentivada 70%',
    },
    {
      title: 'Investimentos',
      value: 'SMF',
    },
  ];

  if (managementPrice && managementPrice > 0) {
    if (managementPrice) {
      premises.push({
        title: 'Custo de Gestão / mês',
        value: (managementPrice && numeral(managementPrice).format('$ 0,0.00')) ?? '-',
      });
    }
  }

  return premises;
};

export const parseProductTwoProposalScenarios = (
  simulationInputs?: IProductTwoSimulatorInputs,
  bestRetailTraderResult?: IProductTwoBestSimulationResult,
  groupMonthlyManagementPrice?: number,
): IProductTwoSimulationExtendedResult | undefined => {
  const simulationResult = bestRetailTraderResult?.calculatorMetadata;
  if (!simulationResult) return undefined;

  const simulationResultYearsList = Object.keys(simulationResult?.totalSavingByYear ?? {});

  const parseMarketsFinancialDataComparison = (
    currentSpentByYear: Record<string, number>,
    savingByYearInPercentage: Record<string, number>,
    targetSpentByYear: Record<string, number>,
  ) => {
    const targetSpentByYearList = simulationResultYearsList.map((year) => targetSpentByYear[year]);
    const currentSpentByYearList = simulationResultYearsList.map((year) => currentSpentByYear[year]);
    const savingByYearPercentageList = simulationResultYearsList.map((year) =>
      Math.round(savingByYearInPercentage[year] * 100),
    );
    const targetSpent = targetSpentByYearList.reduce((accumulated, value) => accumulated + value);
    const currentSpent = currentSpentByYearList.reduce((accumulated, value) => accumulated + value);

    return {
      currentSpent,
      currentSpentByYear,
      currentSpentByYearList,
      savingByYearPercentageList,
      targetSpent,
      targetSpentByYear,
      targetSpentByYearList,
    };
  };

  const parsedUnits: Array<IProductTwoSimulationUnitExtendedResult> = simulationResult.unitsReports.map(
    (unit: IUnitReport) => {
      const unitInputs = simulationInputs?.units?.find((auxiliaryUnit) => auxiliaryUnit.nickname === unit.nickname);
      const parsedUnitAclPrices = parseEnergyPrices(unit.priceList, simulationInputs?.targetYear as number);
      const parsedUnitPremises = parseProductTwoSimulationPremises(
        simulationInputs?.energyType as string,
        unitInputs?.managementPrice as number,
        unitInputs?.supplyStartDate,
        simulationInputs?.targetYear as number,
        unit.averageConsumption,
      );

      const {
        currentSpent,
        currentSpentByYear,
        currentSpentByYearList,
        savingByYearPercentageList,
        targetSpent,
        targetSpentByYear,
        targetSpentByYearList,
      } = parseMarketsFinancialDataComparison(
        unit.currentSpentByYear,
        unit.savingByYearInPercentage,
        unit.targetSpentByYear,
      );

      return {
        ...unit,
        aclPrices: parsedUnitAclPrices,
        totalSavingByMonth: unit?.savingByMonth,
        currentSpent,
        currentSpentByYear: currentSpentByYear,
        currentSpentByYearList,
        initialExpenses: unit.initialExpenses,
        premises: parsedUnitPremises,
        proposalValidity: bestRetailTraderResult?.pricesExpirationDate,
        retailTraderId: bestRetailTraderResult?.retailTraderId,
        saving: unit.totalSaving,
        savingByYear: unit.savingByYear,
        savingByYearPercentageList,
        savingPercentage: unit.totalSavingInPercentage,
        simulationResultYearsList,
        targetSpent,
        targetSpentByYear: targetSpentByYear,
        targetSpentByYearList,
        validPrices: bestRetailTraderResult?.inputs.validPrices,
      };
    },
  );

  const {
    currentSpent,
    currentSpentByYear,
    currentSpentByYearList,
    savingByYearPercentageList,
    targetSpent,
    targetSpentByYear,
    targetSpentByYearList,
  } = parseMarketsFinancialDataComparison(
    simulationResult.totalCurrentSpentByYear,
    simulationResult.totalSavingByYearPercentage,
    simulationResult.totalTargetSpentByYear,
  );

  const supplyStartDate = simulationInputs?.units ? simulationInputs?.units[0].supplyStartDate : '-';
  const parsedPremises = parseProductTwoSimulationPremises(
    simulationInputs?.energyType as string,
    groupMonthlyManagementPrice,
    supplyStartDate,
    simulationInputs?.targetYear as number,
    simulationResult.totalAverageConsumption,
  );

  const parsedAclPrices = parseEnergyPrices(
    simulationResult.unitsReports[0].priceList,
    simulationInputs?.targetYear as number,
  );

  return {
    ...simulationResult,
    // @ts-expect-error - unitsValidationsProps is dinamically generated
    aclPrices: parsedAclPrices,
    totalSavingByMonth: simulationResult?.totalSavingByMonth,
    leadName: '',
    currentSpent,
    currentSpentByYear: currentSpentByYear,
    currentSpentByYearList,
    initialExpenses: simulationResult.totalInitialExpenses,
    premises: parsedPremises,
    proposalValidity: bestRetailTraderResult?.pricesExpirationDate as string,
    retailTraderId: bestRetailTraderResult?.retailTraderId,
    saving: simulationResult.totalSaving,
    savingByYear: simulationResult.totalSavingByYear,
    savingByYearPercentageList,
    savingPercentage: simulationResult.totalSavingPercentage,
    simulationResultYearsList: simulationResultYearsList,
    targetSpent,
    targetSpentByYear: targetSpentByYear,
    targetSpentByYearList,
    units: parsedUnits,
    validPrices: bestRetailTraderResult?.inputs.validPrices,
  };
};
