import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';
import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';
import { EnergyTypeEnum, ISimulationPayloadBase } from '@schemas/types';

export function SimulatiorFormEnergyTypeComparisonSection<T>() {
  const { setValue } = useFormContext();
  const aux__useEnergyTypeComparison = useWatch<ISimulationPayloadBase>({
    name: 'aux__useEnergyTypeComparison',
  });
  const { user } = useAuth();

  const [showComparisonSection, setShowComparisonSection] = React.useState<boolean>(aux__useEnergyTypeComparison);

  const comparisonSectionHandler = () => {
    setValue('aux__useEnergyTypeComparison', !showComparisonSection);
    return setShowComparisonSection(!showComparisonSection);
  };
  React.useEffect(() => {
    !aux__useEnergyTypeComparison && setValue('energyType', undefined);
  }, [aux__useEnergyTypeComparison]);

  return (
    <>
      {!user?.isCommercialDealer && !user?.isWhiteLabel && (
        <div className="flex flex-col gap-5 -mt-5 bg-neutral-10 pt-5 pb-5 mb-6 px-6 rounded-medium">
          <CheckboxFormField<ISimulationPayloadBase>
            data-cy="check-box-show-energy-type-comparison"
            label=""
            field="aux__useEnergyTypeComparison"
            inputOptions={[
              {
                id: 'enableEnergyTypeComparison',
                value: 'enableEnergyTypeComparison',
                optionLabel: 'Comparativo de tipos de energia',
                defaultChecked: aux__useEnergyTypeComparison || showComparisonSection,
              },
            ]}
            options={{
              onChange: comparisonSectionHandler,
            }}
          />
          {aux__useEnergyTypeComparison && (
            <SelectFormField<ISimulationPayloadBase>
              id="energyType"
              field="energyType"
              label="Informar tipo de Energia"
              inputOptions={Object.entries(EnergyTypeEnum).map((energyTypeEntry) => ({
                value: energyTypeEntry[0],
                optionLabel: energyTypeEntry[1],
              }))}
              options={{ required: { value: true, message: 'O tipo de energia é obrigatório' } }}
            />
          )}
        </div>
      )}
    </>
  );
}
