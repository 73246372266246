import { IWhiteLabelSupplier, IWhiteLabelSupplierPayload } from '@schemas/white-label/types';
import { transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';
import { parseMultipartPayload } from '@utils/form-data';

export const parseWhiteLabelSupplier = (
  whiteLabelSupplierSupplierRegistration: Record<string, any>,
): IWhiteLabelSupplier => {
  return transformObjectKeysToCamelCase(whiteLabelSupplierSupplierRegistration) as IWhiteLabelSupplier;
};

export const parseWhiteLabelSuppliers = (
  whiteLabelSupplierSupplierRegistration: Record<string, any>,
): Array<IWhiteLabelSupplier> => {
  return transformObjectKeysToCamelCase(whiteLabelSupplierSupplierRegistration) as IWhiteLabelSupplier[];
};

export const parseWhiteLabelSupplierPayload = (payload: IWhiteLabelSupplierPayload | IWhiteLabelSupplier) => {
  const cleanedPayload = { ...payload };

  const snakedCasePayload = transformObjectKeysToSnakeCase(cleanedPayload);
  return snakedCasePayload;
};

export const parseDataWhiteLabelSupplier = (whiteLabelSupplierSupplierData: IWhiteLabelSupplier) => {
  const result = transformObjectKeysToSnakeCase(whiteLabelSupplierSupplierData);
  return result;
};
