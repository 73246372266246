import { FeedbackNotificationProps } from '@clarke-energia/foton';

export const ENERGY_DISTRIBUTION_COMPANIES: Record<number, string> = {
  1: 'Coelba',
  2: 'Celpe',
  3: 'Cosern',
  4: 'CPFL Santa Cruz',
  5: 'Energisa MT',
  6: 'CPFL Paulista',
  7: 'Energisa MS',
  8: 'Energisa BO',
  9: 'Light',
  10: 'Energisa SE',
  11: 'Enel CE',
  12: 'Enel RJ',
  13: 'Sulgipe',
  14: 'Uhenpal',
  15: 'Cemig',
  16: 'RGE',
  17: 'Copel',
  19: 'Energisa MR',
  20: 'Cocel',
  22: 'Energisa TO',
  23: 'Energisa SS',
  24: 'Eletrocar',
  25: 'Demei',
  26: 'Hidropan',
  27: 'MUX-Energia',
  28: 'Equatorial PA',
  29: 'EDP ES',
  30: 'ELFSM',
  31: 'Celesc',
  32: 'Forcel',
  33: 'Elektro',
  34: 'Equatorial MA',
  35: 'Energisa PB',
  36: 'Cooperaliança',
  37: 'EFLJC',
  38: 'EFLUL',
  39: 'DCELT',
  40: 'Equatorial AL',
  41: 'Equatorial PI',
  42: 'CEB',
  43: 'Enel GO',
  44: 'EDP SP',
  45: 'CPFL Piratininga',
  46: 'AME',
  47: 'Roraima Energia',
  48: 'DMED',
  49: 'Chesp',
  50: 'CEEE',
  51: 'Energisa AC',
  52: 'CEA',
  53: 'Energisa RO',
  54: 'Cerrp',
  55: 'Ceripa',
  56: 'Ceres',
  57: 'Cercos',
  58: 'Cernhe',
  59: 'Cerpro',
  60: 'Certaja',
  61: 'Ceris',
  62: 'Ceral Arapoti',
  63: 'Cermissões',
  64: 'Cooperluz',
  65: 'Coprel',
  66: 'Certel',
  67: 'Ceriluz',
  68: 'Creluz-D',
  69: 'Creral',
  70: 'Cedrap',
  71: 'Cedri',
  72: 'Cejama',
  73: 'Ceraçá',
  74: 'Cerbranorte',
  75: 'Cerej',
  76: 'Cergal',
  77: 'Cergapa',
  78: 'Cergral',
  79: 'Cermoful',
  80: 'Cerpalo',
  81: 'Cersul',
  82: 'Certrel',
  83: 'Coopercocal',
  84: 'Coopera',
  85: 'Coopermila',
  86: 'Coorsel',
  87: 'Ceral Anitápolis',
  88: 'Cerim',
  89: 'Cetril',
  90: 'Cermc',
  91: 'Ceprag',
  102: 'Enel SP',
};

export const COMPANY_TYPES = {
  4: 'Fábrica',
  7: 'Posto de Gasolina',
  9: 'Restaurante',
  11: 'Outro',
  12: 'Fábrica de Mármore',
  13: 'Hospital',
};

export const OFF_PEAK = 'OffPeak';
export const CONSUMPTION_OFF_PEAK = 'consumptionOffPeak';
export const CONSUMPTION_PEAK = 'consumptionPeak';
export const CONTRACTED_DEMAND_OFF_PEAK = 'contractedDemandOffPeak';
export const CONTRACTED_DEMAND_PEAK = 'contractedDemandPeak';
export const MEASURED_DEMAND_OFF_PEAK = 'measuredDemandOffPeak';
export const MEASURED_DEMAND_PEAK = 'measuredDemandPeak';
export const HISTORY_TYPE_WITH_ARRAY = ['measuredDemandOffPeak', 'measuredDemandPeak'];

export const A4_VERDE = { label: 'a4-verde', friendlyName: 'A4 Verde', value: 2 };
export const A4_AZUL = { label: 'a4-azul', friendlyName: 'A4 Azul', value: 3 };

export enum TARIFF {
  A4_GREEN = 2,
  A4_BLUE = 3,
  AS_BLUE = 4,
  AS_GREEN = 5,
  B3_STANDARD = 8,
  B3_OPTING = 9,
}
export enum TARIFF_LABELS {
  A4_GREEN = 'A4 Verde',
  A4_BLUE = 'A4 Azul',
  AS_BLUE = 'AS Azul',
  AS_GREEN = 'AS Verde',
  B3_STANDARD = 'BT Padrão',
  B3_OPTING = 'BT Optante',
  B3_COMMERCIAL = 'BT Comercial',
}

export const TARIFFS_INDEXER: Record<TARIFF, { subgroup: string; tariff_modality: string }> = {
  2: { subgroup: 'A4', tariff_modality: 'Verde' },
  3: { subgroup: 'A4', tariff_modality: 'Azul' },
  4: { subgroup: 'AS', tariff_modality: 'Azul' },
  5: { subgroup: 'AS', tariff_modality: 'Verde' },
  // Indexes 6 and 7 are used for unused high voltage tariffs in backend
  8: { subgroup: 'Baixa Tensão', tariff_modality: 'Padrão' },
  9: { subgroup: 'Baixa Tensão', tariff_modality: 'Optante' },
};

export const amountsByYear = (list: any[]): Array<Record<string, number | string>> =>
  list.map((item) => ({
    year: item[0],
    amount: item[1],
  }));

export const getMonthNameFromIndex = (idx: number): string =>
  ({
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  }[idx] || String(idx));

export const yearsList = [1, 2, 3, 4, 5, 6, 7];
export const yearsListCommercialDealer = [1, 2, 3, 4];

export const energySolutionsList: Record<string, string> = {
  DISTRIBUTED_GENERATION: 'Geração Distribuida',
  FIXED_PRICE: 'Mercado Livre - Preço Fixo',
  GUARANTEED_DISCOUNT: 'Mercado Livre - Desconto Garantido',
  SELF_PRODUCER: 'Mercado Livre - Autoprodutor',
};

export const ClarkeRetailTraderId = '8684c97c-6492-4606-abce-420716b9973a';

export interface INotificationFeedbackContent {
  label: string;
  message: string;
  kind: FeedbackNotificationProps['kind'];
}

export const DEFAULT_MANAGEMENT_PRICE = 200;

export const LOW_TENSION_OPTING_INITIAL_EXPENSES = 10000;
export const LOW_TENSION_STANDARD_INITIAL_EXPENSES = 100000;
