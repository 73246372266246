import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  IProductTwoSimulationExtendedResult,
  IProductTwoSimulatorInputs,
  IProductTwoProposal,
} from '@schemas/product-two/types';

import { parseProductTwoProposal, parseProductTwoProposalScenarios } from '@contexts/product-two/parser';
import useProductTwo from '@hooks/use-product-two';

import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';

import { Container } from '@components/molecules/general/simulation-styled-components';
import AboutSectionProposalContent from '@components/organisms/new-proposal/about';
import { FeasibilitySectionProposalSection } from '@components/organisms/new-proposal/feasibility';
import { ManagementSectionProposalSection } from '@components/organisms/new-proposal/management/index';
import { NextStepSectionProposalSection } from '@components/organisms/new-proposal/next-steps';
import ProductTwoSimulationGroupEconomySection from '@components/organisms/product-two/simulation/product-two-simulation-group-economy-section';
import ProductTwoSimulationUnitsEconomySection from '@components/organisms/product-two/simulation/product-two-simulation-units-economy-section';
import ProductTwoSimulationConclusiveSection from '@components/organisms/product-two/simulation/product-two-simulation-conclusive-section';
import { EnergyTypeComparasionSection } from '@components/organisms/new-proposal/energy-comparasion';
import LoadingPage from '@components/templates/loading-page';
import ErrorUpdateTheProposal from '@pages/error-update-the-proposal';

const getGroupManagementPrice = (simulationInputs?: IProductTwoSimulatorInputs) => {
  return simulationInputs?.units?.reduce((accumulated, unit) => accumulated + (unit.managementPrice as number), 0);
};

const ProductTwoSimulation = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getProductTwoProposalById, isValidProposalProductTwo, isLoadingProductTwo } = useProductTwo();
  const [proposal, setProposal] = React.useState<IProductTwoProposal>();
  const [simulationScenarios, setSimulationScenarios] = React.useState<IProductTwoSimulationExtendedResult>();
  const [groupManagementPrice, setGroupManagementPrice] = React.useState<number>();
  const [exclusiveTrader, setExclusiveTrader] = React.useState<boolean | undefined>(undefined);

  const getProposalScenariosAndGroupManagementPrice = (proposal: IProductTwoProposal) => {
    const bestRetailTraderResult = proposal?.calculatorMetadata?.bestRetailTraderResult;
    const simulationInputs = bestRetailTraderResult?.inputs;

    const groupManagementPrice = getGroupManagementPrice(simulationInputs);
    setGroupManagementPrice(groupManagementPrice);

    const parsedSimulationScenarios = parseProductTwoProposalScenarios(
      simulationInputs,
      bestRetailTraderResult,
      groupManagementPrice,
    );
    setSimulationScenarios(parsedSimulationScenarios);
  };

  async function getProposal(id: string) {
    try {
      const { data: response } = await getProductTwoProposalById(id);
      const parsedProposal = parseProductTwoProposal(response.data);
      setProposal(parsedProposal);
      setExclusiveTrader(parsedProposal?.inputs?.exclusiveTrader);
      getProposalScenariosAndGroupManagementPrice(parsedProposal);
    } catch (error) {
      navigate(SIMULATION_NOT_FOUND_PATH);
    }
  }

  React.useEffect(() => {
    id && getProposal(id);
  }, [id]);

  function RenderProposalSections() {
    if (exclusiveTrader === true) {
      return (
        <section className="section-core-proposal">
          <AboutSectionProposalContent />
          <FeasibilitySectionProposalSection />
          <ManagementSectionProposalSection simulationResults={simulationScenarios} />
          <EnergyTypeComparasionSection />
          <NextStepSectionProposalSection />
        </section>
      );
    } else {
      return (
        <Container className="flex flex-col items-center mx-0 px-0">
          {proposal && simulationScenarios && (
            <ProductTwoSimulationGroupEconomySection leadName={proposal?.lead} {...{ proposal, simulationScenarios }} />
          )}
          <ProductTwoSimulationUnitsEconomySection simulationScenarios={simulationScenarios} />
          <ProductTwoSimulationConclusiveSection
            simulationScenarios={simulationScenarios}
            groupManagementPrice={groupManagementPrice}
          />
        </Container>
      );
    }
  }
  if (isLoadingProductTwo) {
    return <LoadingPage />;
  } else {
    if (isValidProposalProductTwo) {
      return <RenderProposalSections />;
    } else if (!isValidProposalProductTwo && proposal?.createdAt) {
      return <ErrorUpdateTheProposal />;
    }
  }
  return null;
};

export default ProductTwoSimulation;
