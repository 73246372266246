import { IProductTwoProposal } from '@schemas/product-two/types';
import { IProductOneProposal } from '@contexts/product-one/types';

import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';

import {
  ChartData,
  extractAnnualDetail,
  extractAnnualDetailRetail,
  extractChartData,
  extractChartDataRetail,
  extractEconomyData,
  IAnnualDetail,
} from '../helpers';

export function parseRetailFeasibilityData() {
  const { proposal: retailProposal } = useProductTwo();

  const chartData = extractChartDataRetail(
    retailProposal?.calculatorMetadata?.bestRetailTraderResult?.calculatorMetadata,
  );
  const annualDetail = extractAnnualDetailRetail(
    retailProposal?.calculatorMetadata?.bestRetailTraderResult?.calculatorMetadata,
  );

  const annualRef = annualDetail.length;
  const gridCols = annualRef > 5 ? 4 : annualRef;
  const lastCard = annualDetail.length - 1;

  const economyData = {
    ...extractEconomyData(
      retailProposal?.calculatorMetadata?.bestRetailTraderResult
        ?.inputs as IProductTwoProposal['calculatorMetadata']['bestRetailTraderResult']['inputs'],
      retailProposal?.calculatorMetadata?.bestRetailTraderResult
        ?.calculatorMetadata as IProductTwoProposal['calculatorMetadata']['bestRetailTraderResult']['calculatorMetadata'],
    ),
  };
  const leadName = retailProposal?.lead?.name;

  return {
    leadName,
    chartData,
    annualDetail,
    annualRef,
    gridCols,
    lastCard,
    economyData,
    pricesExpirationDate: retailProposal?.calculatorMetadata?.bestRetailTraderResult?.pricesExpirationDate,
    energyType: retailProposal?.inputs?.energyType ?? 'INCENTIVIZED_50',
  };
}

export function parseManagementFeasibilityData() {
  const { proposal: managementProposal } = useProductOne();
  const chartData =
    managementProposal?.calculatorMetadata?.firstResult &&
    extractChartData(managementProposal?.calculatorMetadata?.firstResult);
  const annualDetail =
    managementProposal?.calculatorMetadata?.firstResult &&
    extractAnnualDetail(managementProposal?.calculatorMetadata?.firstResult);

  const annualRef = annualDetail && annualDetail.length;
  const gridCols = annualRef && annualRef > 5 ? 4 : annualRef;
  const lastCard = annualDetail && annualDetail.length - 1;

  const economyData = {
    ...extractEconomyData(
      managementProposal?.calculatorMetadata?.inputs as IProductOneProposal['calculatorMetadata']['inputs'],
      managementProposal?.calculatorMetadata?.firstResult as IProductOneProposal['calculatorMetadata']['firstResult'],
    ),
  };

  const scenariosRiskComparisonData = managementProposal?.calculatorMetadata?.firstResult?.scenariosRiskComparison;
  const risk = managementProposal?.calculatorMetadata?.inputs?.risk;
  const leadName = managementProposal?.lead?.name;

  return {
    leadName: leadName as string,
    chartData: chartData as ChartData,
    annualDetail: annualDetail as IAnnualDetail[],
    annualRef: annualRef as number,
    gridCols: gridCols as number,
    lastCard: lastCard as number,
    economyData,
    scenariosRiskComparisonData,
    risk,
    proposalValidity: managementProposal?.calculatorMetadata?.inputs?.proposalValidity,
    energyType: managementProposal?.inputs?.energyType ?? 'INCENTIVIZED_50',
  };
}
