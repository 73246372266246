import * as z from 'zod';

const isValidHexRgb = (value?: string) => (value ? /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value) : false);

const WhiteLabelSupplierColorsSchema = z.object({
  main: z.string().optional(),
  darker: z.string().optional(),
  brighter: z.string().optional(),
});

const WhiteLabelSupplierPricesSchema = z.object({
  management: z.number().nullable().optional(),
  migration: z.number().nullable().optional(),
  discount: z.number().nullable().optional(),
});

const WhiteLabelSupplierDifferencesSchema = z.object({
  title: z.string().optional(),
  icon: z.string().optional(),
  description: z.string().optional(),
});

const WhiteLabelSupplierUsersSchema = z.object({
  id: z.string().uuid({ message: 'O ID deve ser um UUID válido' }),
  name: z
    .string()
    .min(3, { message: 'O nome deve ter no mínimo 3 caracteres' })
    .max(50, { message: 'O nome deve ter no máximo 50 caracteres' }),
  email: z.string().email({ message: 'O e-mail deve ser um endereço válido' }),
  pictureUrl: z.string().optional(),
});

const WhiteLabelSupplierPresentationSchema = z.object({
  logoUrl: z.string().optional(),
  name: z
    .string()
    .min(3, { message: 'O nome deve ter no mínimo 3 caracteres' })
    .max(50, { message: 'O nome deve ter no máximo 50 caracteres' }),
  phone: z.string().refine((value) => value.length === 11 && /^\d{2}\d{5}\d{4}$/.test(value), {
    message: 'O telefone deve ser um número válido com DDD',
  }),
  email: z.string().email({ message: 'O e-mail deve ser um endereço válido' }),
});

const basePayloadSchema = z.object({
  createdAt: z.string().optional(),
  aux__usePrices: z.boolean().optional(),
  presentation: WhiteLabelSupplierPresentationSchema,
  users: z.array(WhiteLabelSupplierUsersSchema).min(1, {
    message: 'Pelo menos um usuário deve estar vinculado',
  }),
  type: z
    .enum(['MANAGER', 'TRADER', 'HYBRID'], {
      message: 'Insira um valor',
    })
    .nullable()
    .optional(),
});

const WhiteLabelSupplierAddSchema = basePayloadSchema.extend({
  colors: WhiteLabelSupplierColorsSchema.optional(),
  differences: z.array(WhiteLabelSupplierDifferencesSchema).optional(),
  prices: WhiteLabelSupplierPricesSchema.optional(),
  fileList: z
    .instanceof(File, {
      message: 'O arquivo deve ser um arquivo válido',
    })
    .refine((file) => file instanceof File && file.size <= 5 * 1024 * 1024, {
      message: 'O arquivo deve ter menos de 5MB',
    })
    .refine((file) => file instanceof File && file.type === 'image/png', {
      message: 'O arquivo deve ser um PNG',
    }),
});

const WhiteLabelSupplierEditSchema = basePayloadSchema
  .extend({
    colors: z.object({
      main: z.string().refine((value) => isValidHexRgb(value), { message: 'A cor principal deve ser um RGB válido' }),
      darker: z.string().refine((value) => isValidHexRgb(value), { message: 'A cor escura deve ser um RGB válido' }),
      brighter: z.string().refine((value) => isValidHexRgb(value), { message: 'A cor clara deve ser um RGB válido' }),
    }),
    differences: z
      .array(
        WhiteLabelSupplierDifferencesSchema.superRefine((difference, ctx) => {
          const { title, description, icon } = difference;
          if (!title || title.length < 3) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'O título deve ter no mínimo 3 caracteres',
              path: ['title'],
            });
          }
          if (title && title.length > 40) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'O título deve ter no máximo 40 caracteres',
              path: ['title'],
            });
          }
          if (!description || description.length < 20) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'A descrição deve ter no mínimo 20 caracteres',
              path: ['description'],
            });
          }
          if (description && description.length > 150) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'A descrição deve ter no máximo 150 caracteres',
              path: ['description'],
            });
          }
          if (icon === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Um ícone deve ser selecionado',
              path: ['icon'],
            });
          }
          return true;
        }),
      )
      .refine((value) => value && value.length <= 4, { message: 'As diferenças devem ter no máximo 4 itens' }),
    prices: WhiteLabelSupplierPricesSchema.optional(),
    fileList: z.object({}).or(z.instanceof(File)).optional(),
    createdAt: z.string().optional(),
    deletedAt: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const { aux__usePrices, prices, fileList, presentation } = data;
    if (aux__usePrices) {
      const { discount, management, migration } = prices as {
        management: number;
        migration: number;
        discount: number;
      };
      if (discount > 99) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['prices', 'discount'],
          message: 'O desconto deve ser menor que 100%',
        });
      }
      if (discount < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['prices', 'discount'],
          message: 'Valor deve ser maior que 0',
        });
      }
      if (migration < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['prices', 'migration'],
          message: 'Valor deve ser maior que 0',
        });
      }
      if (management < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['prices', 'management'],
          message: 'Valor deve ser maior que 0',
        });
      }
    }
    if (!fileList && !presentation.logoUrl) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Um arquivo deve ser fornecido se não houver URL de logo',
        path: ['fileList'],
      });
    }
  });
export {
  WhiteLabelSupplierColorsSchema,
  WhiteLabelSupplierPricesSchema,
  WhiteLabelSupplierDifferencesSchema,
  WhiteLabelSupplierUsersSchema,
  WhiteLabelSupplierPresentationSchema,
  WhiteLabelSupplierAddSchema,
  WhiteLabelSupplierEditSchema,
};
