import useWhiteLabel from '@hooks/use-white-label';
import UnitsSaving from '@components/molecules/new-proposal/feasibility-analysis/feasibility-simulation/units-saving';
import { FeasibilitySimulation } from './simulation';
import FeasibilityAnalysis from './feasibility-analysis';
import FeasibilitySimulationCharts from './feasibility-simulation-charts';
import { parseManagementFeasibilityData, parseRetailFeasibilityData } from './retail';
import ScenariosRiskComparison from './scenarios-risk-comparison';

export function FeasibilitySectionProposalSection() {
  const { isWhiteLabelProposal } = useWhiteLabel();
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const isRetailPath = window.location.pathname.includes('clarke-varejo');
  const scenariosRiskComparisonData = parseManagementFeasibilityData()?.scenariosRiskComparisonData;

  const risk = parseManagementFeasibilityData()?.risk;

  return (
    <>
      {isManagementPath && (
        <>
          <FeasibilityAnalysis />
          {!isWhiteLabelProposal && <FeasibilitySimulationCharts />}
          <FeasibilitySimulation {...parseManagementFeasibilityData()} />
          {!isWhiteLabelProposal && scenariosRiskComparisonData && risk && (
            <ScenariosRiskComparison
              isWhiteLabelProposal={isWhiteLabelProposal}
              risk={risk}
              scenariosRiskComparisonData={scenariosRiskComparisonData}
            />
          )}
          <UnitsSaving />
        </>
      )}
      {isRetailPath && (
        <>
          <FeasibilityAnalysis />
          <FeasibilitySimulation {...parseRetailFeasibilityData()} />
          <UnitsSaving />
        </>
      )}
    </>
  );
}
