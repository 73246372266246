import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserGroup, UserInfo } from '@contexts/users/types';
import axios from 'axios';
import config from '@config';

export interface AuthenticationState {
  isAuthenticated: boolean;
  accessToken?: string;
}

export interface IAuthContext {
  authStatus: AuthenticationState;
  user?: UserInfo;
  logout: () => void;
  loading: boolean;
  token?: string;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export const useAuth = () => useContext(AuthContext);

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [user, setUser] = useState<UserInfo | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isDevMode = window.location.origin.includes('localhost');
  const currentDomain = window.location.origin;
  const bffPort = '5001';
  const localHost = `${currentDomain.replace(/:\d+/, `:${bffPort}`)}`;
  const virtualHost = 'https://teste-bff.vercel.app';
  const bffBaseURL = `${!isDevMode ? localHost : virtualHost}/auth`;

  const bffApiClient = axios.create({
    baseURL: bffBaseURL,
    withCredentials: true,
  });
  useEffect(() => {
    const checkSession = async () => {
      try {
        await bffApiClient.get('/');
        const { data } = await bffApiClient.get('/profile');
        const { user, token } = data.userAuthData;
        setUser(parseUserInfo(user));
        setToken(token);
        token && setIsAuthenticated(true);
      } catch (error: Error | any) {
        window.location.href = `${bffBaseURL}/login`;
      } finally {
        setLoading(false);
      }
    };

    !isAuthenticated && checkSession();
  }, []);

  const parseUserInfo = (userData: any): UserInfo | undefined => {
    if (!userData) return undefined;
    const userGroups = userData['https://clarke.com.br/groups'] as UserGroup[];
    return {
      id: userData['https://clarke.com.br/uuid'],
      name: userData.name,
      email: userData.email,
      pictureUrl: userData.picture,
      isSuperAdmin: userGroups.includes('Admin Especial de Vendas'),
      isAdmin: userGroups.includes('Vendas Admin'),
      isCommercialDealer: userGroups.includes('Vendas Revendedor'),
      isWhiteLabel: userGroups.includes('Vendas White Label'),
      roles: userData['https://clarke.com.br/roles'],
      groups: userGroups,
      phone: userData.phone_number,
    };
  };

  const logout = async () => {
    try {
      await bffApiClient.get('/logout');
      window.location.href = `${bffBaseURL}/login`;
      setUser(undefined);
      setIsAuthenticated(false);
    } catch {
      window.location.reload();
    }
  };

  const contextValue: IAuthContext = {
    authStatus: { isAuthenticated, accessToken: token },
    user,
    logout,
    loading,
    token,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthWrapper;
