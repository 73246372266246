import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

import {
  GuaranteeTypeEnum,
  ISimulationCompetitorPayloadBase,
  ISimulationPayloadBase,
  ISimulationUnitPayloadBase,
  ManagementTerminationFineEnum,
  ManagerTypeEnum,
  SimulatorTypeEnum,
  ChargesCoverageTypeEnum,
} from '@schemas/types';
import { addWorkdays, startDate } from '@utils/datetime';

import { DEFAULT_MANAGEMENT_PRICE } from '../../../../utils/constants';

const simulatorsIndexer: Record<keyof typeof SimulatorTypeEnum, Record<string, any> | undefined> = {
  CLARKE_MANAGEMENT: {
    managementPrice: 1000,
    targetYears: [3],
  },
  CLARKE_RETAIL: {
    productType: 'ACL_RETAILER',
    targetYear: 3,
    exclusiveTrader: true,
  },
  CONTRACTED_DEMAND: undefined,
  WHITE_LABEL: {
    managementPrice: 1000,
    targetYears: [3],
  },
};

const getDefaultProposalValidity = (simulator?: keyof typeof SimulatorTypeEnum) => {
  const currentDate = dayjs();
  const daysDiff = simulator === 'CLARKE_MANAGEMENT' ? 1 : 15;

  let resultValidity = currentDate.add(daysDiff, 'days');

  if (resultValidity.isoWeekday() === 6) resultValidity = resultValidity.add(2, 'days');
  else if (resultValidity.isoWeekday() === 7) resultValidity = resultValidity.add(1, 'days');

  return resultValidity.format('DD/MM/YYYY');
};

export const compareAndGetProposalValidity = (
  proposalValidity?: string,
  simulator?: keyof typeof SimulatorTypeEnum,
): string => {
  const defaultProposalValidity = getDefaultProposalValidity();
  const currentDate = dayjs();
  const maxValidityDate = addWorkdays(currentDate, 5); // Maximum: Current day + 5 workdays

  if (simulator && simulator === 'CLARKE_MANAGEMENT') {
    const clarkeDate = addWorkdays(startDate(), 2);
    return clarkeDate.isAfter(currentDate) ? clarkeDate.format('DD/MM/YYYY') : currentDate.format('DD/MM/YYYY');
  }

  if (!proposalValidity) return defaultProposalValidity;

  const proposalValidityDate = dayjs(proposalValidity, 'DD/MM/YYYY');

  // Ensure the date is within range
  if (proposalValidityDate.isBefore(currentDate)) {
    return defaultProposalValidity; // Below current date, return default
  }
  if (proposalValidityDate.isAfter(maxValidityDate)) {
    return maxValidityDate.format('DD/MM/YYYY'); // Beyond max date, return max
  }

  return proposalValidityDate.format('DD/MM/YYYY');
};

export const calculateUnitSupplyStartDate = (onlyReturnYear: boolean, isAlredyAtAcl: boolean) => {
  const currentDate = dayjs();
  const calculatedsupplyStartDate = currentDate.add(180, 'days');

  const adjustedsupplyStartDate =
    calculatedsupplyStartDate.date() === 1
      ? calculatedsupplyStartDate
      : calculatedsupplyStartDate.add(1, 'month').startOf('month');

  const minimumsupplyStartDate = dayjs('01/01/2025', 'DD/MM/YYYY');

  const finalsupplyStartDate = adjustedsupplyStartDate.isBefore(minimumsupplyStartDate)
    ? minimumsupplyStartDate
    : adjustedsupplyStartDate;

  if (onlyReturnYear) return finalsupplyStartDate.format('YYYY');
  else return finalsupplyStartDate.format('DD/MM/YYYY');
};

export const getSimulatorUnitDefaultValues = (
  simulatorType?: keyof typeof SimulatorTypeEnum,
): ISimulationUnitPayloadBase => {
  const managementPrice =
    (simulatorType && simulatorsIndexer[simulatorType]?.managementPrice) ?? DEFAULT_MANAGEMENT_PRICE;

  return {
    consumptionLowVoltage: {},
    consumptionOffPeak: {},
    consumptionPeak: {},
    supplyStartDate: calculateUnitSupplyStartDate(false, false),
    powerGeneratorCost: 0,
    tariff: 2,
    managementPrice,
    initialExpenses: 0,
  } as ISimulationUnitPayloadBase;
};

export const getSimulatorCompetitorDefaultValues = (
  simulatorType?: string,
  isClarke?: boolean,
): ISimulationCompetitorPayloadBase => {
  const flexibilityInitialValue = simulatorType === 'CLARKE_RETAIL' ? 100 : undefined;
  const chargesCoverageTypeInitialValue: keyof typeof ChargesCoverageTypeEnum | undefined =
    simulatorType === 'CLARKE_RETAIL' ? 'FULL_COVERAGE_FOR_ENERGY_CONTRACT' : undefined;
  const managementTerminationFineInitialValue: keyof typeof ManagementTerminationFineEnum | undefined = isClarke
    ? 'THREE_MONTHS_NOTICE'
    : undefined;
  const managerTypeInitialValue: keyof typeof ManagerTypeEnum = isClarke ? 'INDEPENDENT_MANAGER' : 'SALES_MANAGER';
  const guaranteeTypeInitialValue: keyof typeof GuaranteeTypeEnum | undefined = isClarke ? 'NO_GUARANTEE' : undefined;

  return {
    upperFlexibility: flexibilityInitialValue as number,
    lowerFlexibility: flexibilityInitialValue as number,
    chargesCoverageType: chargesCoverageTypeInitialValue as keyof typeof ChargesCoverageTypeEnum,
    managementTerminationFine: managementTerminationFineInitialValue as keyof typeof ManagementTerminationFineEnum,
    managerType: managerTypeInitialValue,
    guaranteeType: guaranteeTypeInitialValue as keyof typeof GuaranteeTypeEnum,
  } as ISimulationCompetitorPayloadBase;
};
export const getSimulatorInitialValues = (simulatorType: keyof typeof SimulatorTypeEnum): ISimulationPayloadBase => {
  const isRedoCalculationPath = window.location.search.includes('refazer-calculo');
  const simulatorIndexedInitialValues = simulatorsIndexer[simulatorType];
  return {
    aclType: 'FIXED_PRICE',
    calculationType: 'month_to_month',
    aux__useManualDiscount: false,
    aux__useManualAclPrice: false,
    aux__useManualMigrationCost: isRedoCalculationPath ? false : true,
    aux__useManualManagementPrice: isRedoCalculationPath ? false : true,
    aux__useCompetitorList: false,
    proposalValidity: getDefaultProposalValidity(simulatorType),
    units: [getSimulatorUnitDefaultValues(simulatorType)],
    manualAclPricesHasChargesCoverage: undefined,
    ...simulatorIndexedInitialValues,
  } as ISimulationPayloadBase;
};
