import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import { IContractedDemandContext, IContractedDemandSimulatorInputs } from '../../schemas/contracted-demand/types';
import { parseContractedDemandSimulationPayload } from './parser';
import { useAuth } from '@auth-wrapper';

export const ContractedDemandContext = createContext({} as IContractedDemandContext);

interface Provider {
  children: React.ReactNode;
}

const ContractedDemandProvider: React.FC<Provider> = ({ children }) => {
  const isDevMode = window.location.origin.includes('localhost');
  const currentDomain = window.location.origin;
  const bffPort = '5001';
  const localHost = `${currentDomain.replace(/:\d+/, `:${bffPort}`)}`;
  const virtualHost = 'https://teste-bff.vercel.app';
  const bffBaseURL = `${!isDevMode ? localHost : virtualHost}/contracted-demand`;

  const bffApiClient = axios.create({
    baseURL: bffBaseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  function createContractedDemandSimulation(simulationData: IContractedDemandSimulatorInputs): AxiosPromise {
    const simulationPayload = parseContractedDemandSimulationPayload(simulationData);
    return bffApiClient.post('/simulation', simulationPayload);
  }

  return (
    <ContractedDemandContext.Provider
      value={{
        createContractedDemandSimulation,
      }}
    >
      {children}
    </ContractedDemandContext.Provider>
  );
};

export default ContractedDemandProvider;
