import React from 'react';
import { FeedbackNotification } from '@clarke-energia/foton';
import { UseFormReturn, useForm, FormProvider } from 'react-hook-form';
import { useAuth } from '@src/auth-wrapper';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName, isObjectEmpty, scrollToFirstError } from '@utils/common';
import {
  compareAndGetProposalValidity,
  getSimulatorInitialValues,
} from '@components/molecules/form/simulator/default-values-getters';
import ProductTwoSimulatorFormCore from '@components/organisms/product-two/simulator/product-two-simulator-form-core';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import { CalculatorFormFooter } from '@components/molecules/calculator-form-footer';

import {
  enforceUnitsManagementPriceSuggestion,
  parseUnitsConsumptionHistory,
} from '@contexts/calculator/units-payload-handlers';
import {
  IOperationHoursRange,
  IOperationHoursRangeSet,
  IProposalUser,
  ISimulationUnitPayloadBase,
} from '@schemas/types';
import { removeYearPrefix } from '@schemas/validations';
import { ProductTwoSimulatorInputsSchema } from '@schemas/product-two/';
import { IProductTwoSimulatorInputs } from '@schemas/product-two/types';

import useCustomClipboard from '@hooks/use-custom-clipboard';
import useProductTwo from '@hooks/use-product-two';
import { zodResolver } from '@hookform/resolvers/zod';

interface IProductTwoSimulatorForm {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductTwoSimulatorForm = ({ setOpenModal, setSuccessModal }: IProductTwoSimulatorForm) => {
  const { CopyFormValues, PasteFormValues, customClipboardFeedbackNotificationContent } = useCustomClipboard();
  const { createProductTwoSimulation } = useProductTwo();
  const formRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const formController = useForm<IProductTwoSimulatorInputs>({
    defaultValues: getSimulatorInitialValues('CLARKE_RETAIL') as IProductTwoSimulatorInputs,
    mode: 'all',
    resolver: zodResolver(ProductTwoSimulatorInputsSchema),
  });
  const {
    watch,
    reset,
    formState: { errors },
  } = formController;

  const formValues = watch();
  const { user } = useAuth();
  const authorInfo =
    user &&
    ({
      id: user.id,
      name: user.name,
      pictureUrl: user.pictureUrl,
    } as IProposalUser);
  const onSubmit = async (values: IProductTwoSimulatorInputs) => {
    setLoading(true);
    const inputs = values;
    const manualAclPrice = inputs.manualAclPrice;
    if (manualAclPrice && Object.keys(manualAclPrice).length === 0) {
      inputs.manualAclPrice = undefined;
    }
    const cleanOperationHours = (operationHoursRange: IOperationHoursRangeSet) => {
      const cleanDay = (day: IOperationHoursRange) => (day?.openingTime === '' || day?.closingTime === '' ? null : day);

      return {
        businessDays: operationHoursRange?.businessDays,
        saturday: cleanDay(operationHoursRange?.saturday ?? { openingTime: '', closingTime: '' }),
        sunday: cleanDay(operationHoursRange?.sunday ?? { openingTime: '', closingTime: '' }),
      };
    };

    const operationHoursRange = inputs.units?.map((unit) => ({
      ...unit,
      operationHoursRange: cleanOperationHours(unit.operationHoursRange),
    }));

    inputs.units = operationHoursRange;

    const parsedUnits = parseUnitsConsumptionHistory(inputs.units) as ISimulationUnitPayloadBase[];
    const unitsWithEnforcedManagementPriceSuggestion = enforceUnitsManagementPriceSuggestion(
      parsedUnits,
    ) as ISimulationUnitPayloadBase[];

    const sellerValue = inputs.seller?.id === undefined ? authorInfo : inputs.seller;
    const cleanedValues = Object.fromEntries(Object.entries(inputs).filter(([key]) => !key.startsWith('aux__')));
    const finalValues: IProductTwoSimulatorInputs = {
      ...cleanedValues,
      customFee: cleanedValues.customFee,
      manualAclPrice: values.aux__useManualAclPrice
        ? {
            [cleanedValues?.targetYear]: removeYearPrefix(cleanedValues?.manualAclPrice[cleanedValues?.targetYear]),
          }
        : null,
      units: user?.isCommercialDealer ? unitsWithEnforcedManagementPriceSuggestion : parsedUnits,
      proposalValidity: compareAndGetProposalValidity(values.proposalValidity as string | undefined),
      author: authorInfo,
      totalMigrationCost: (cleanedValues.totalMigrationCost ?? 0) as number,
      seller: sellerValue,
    };
    CopyFormValues(values, false, false);

    return createProductTwoSimulation(inputs, finalValues)
      .then(() => {
        setOpenModal(true);
        setSuccessModal(true);
        eventTracker.trackEvent(eventLabels.FORM_SUBMITTED, {
          form: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });
      })
      .catch((error) => {
        setSuccessModal(false);
        eventTracker.trackEvent(eventLabels.ERROR_MESSAGE_WHEN_CALCULATION_FAILS, {
          product: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });
        const exception = error.response.data.exception;
        if (exception) {
          const hasSpecificError = Object.values(exception).some((step) =>
            (step as string)?.includes('list index out of range'),
          );

          if (hasSpecificError) {
            alert('Confira sua distribuidora. Parece haver um problema com as tarifas.');
          }
        } else {
          alert('Um erro ocorreu. Tente novamente mais tarde.');
        }

        throw error;
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (location.href?.includes('refazer-calculo')) {
      PasteFormValues(reset, 'CLARKE_RETAIL', false);
    } else {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_SIMULATOR, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
      eventTracker.trackEvent(eventLabels.FORM_STARTED, {
        form: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
    }
  }, []);

  React.useEffect(() => {
    scrollToFirstError(formController);
  }, [errors]);
  return (
    <SimulatorLayoutBase<IProductTwoSimulatorInputs>
      title="Simulador"
      simulatorType="CLARKE_RETAIL"
      simulatorFlow="SIMULATOR_FORM"
      setFormValues={reset}
      formValues={formValues}
    >
      <div className="col-span-full flex flex-col w-full lg:w-3/4 p-7">
        <FormProvider {...formController}>
          {customClipboardFeedbackNotificationContent && (
            <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
              <FeedbackNotification {...customClipboardFeedbackNotificationContent} />
            </div>
          )}
          <ProductTwoSimulatorFormCore {...{ formRef, onSubmit, formController }} simulatorType="CLARKE_RETAIL" />
          <CalculatorFormFooter error={!isObjectEmpty(errors)} {...{ formRef, loading, onSubmit }} />
        </FormProvider>
      </div>
    </SimulatorLayoutBase>
  );
};

export default ProductTwoSimulatorForm;
