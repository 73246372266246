import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';
import { useAuth } from '@src/auth-wrapper';

import { ISimulationPayloadBase } from '@schemas/types';
import useWhiteLabel from '@hooks/use-white-label';

import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';
import { fieldVerificator } from '@components/molecules/form/simulator/helper';
import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '@components/molecules/form/simulator/static-data';
import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';

import { calculateTotalMigrationCostSuggestion } from './helper';

const SimulatorFormTotalMigrationCostSection = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(true);

  const totalMigrationCostValueField = `totalMigrationCost` as keyof ISimulationPayloadBase;

  const { setValue } = useFormContext<ISimulationPayloadBase>();
  const { user } = useAuth();
  const { whiteLabelSupplier } = useWhiteLabel();
  const [units, aux__useManualMigrationCost] = useWatch<ISimulationPayloadBase>({
    name: ['units', 'aux__useManualMigrationCost'],
  });
  const aux__useManualMigrationCostValue = aux__useManualMigrationCost ?? false;

  const setTotalMigrationCostValueSuggestion = () => {
    const totalMigrationCostSuggestion =
      user?.isWhiteLabel && whiteLabelSupplier?.prices?.migration
        ? whiteLabelSupplier.prices?.migration
        : calculateTotalMigrationCostSuggestion(units as ISimulationPayloadBase['units']);

    setValue('totalMigrationCost', totalMigrationCostSuggestion);
  };

  React.useEffect(() => {
    if (whiteLabelSupplier?.prices?.migration) {
      setTotalMigrationCostValueSuggestion();
    }
  }, [whiteLabelSupplier]);

  React.useEffect(() => {
    if (aux__useManualMigrationCostValue) setTotalMigrationCostValueSuggestion();
  }, [units, aux__useManualMigrationCostValue]);

  React.useEffect(() => {
    if (!modalOpen) {
      setValue('totalMigrationCost', null);
    }
  }, [modalOpen]);

  if (!user?.isCommercialDealer) {
    return (
      <div className="flex flex-col gap-4 mt-6">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4">
          <div className="my-4 col-span-full w-full">
            <Button
              icon={modalOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              kind="ghost"
              label="Migração"
              onClick={() => setModalOpen(!modalOpen)}
              type="button"
              className="w-full"
            />
            {modalOpen && (
              <div className="mt-4 mb-5">
                <div className="bg-neutral-10 rounded-medium flex flex-row gap-4 p-5 pb-7 gap-y-4">
                  {fieldVerificator({
                    fieldName: 'totalMigrationCostValue',
                    fieldPriority: 'PRIMARY',
                  }) && (
                    <div className="flex flex-col gap-3">
                      <NumericFormField<ISimulationPayloadBase>
                        id={totalMigrationCostValueField}
                        field={totalMigrationCostValueField}
                        label="Custo de Migração Total"
                        formatProps={{
                          mask: '_',
                          allowNegative: false,
                          fixedDecimalScale: false,
                          decimalSeparator: ',',
                          thousandSeparator: '.',
                          decimalScale: 10,
                          prefix: 'R$ ',
                        }}
                        disabled={aux__useManualMigrationCostValue}
                        tooltipContent={tooltipsContent.unit.totalMigrationCost}
                      />
                      <CheckboxFormField<ISimulationPayloadBase>
                        field="aux__useManualMigrationCost"
                        data-cy="enableAutomaticTotalMigrationCost"
                        label=""
                        inputOptions={[
                          {
                            id: 'enableAutomaticTotalMigrationCost',
                            value: 'enableAutomaticTotalMigrationCost',
                            optionLabel: 'Calcular automaticamente',
                            defaultChecked: aux__useManualMigrationCostValue as boolean,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else return <></>;
};

export default SimulatorFormTotalMigrationCostSection;
