import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import { useAuth } from '@src/auth-wrapper';

import { ISalesContext } from './types';

export const SalesContext = createContext({} as ISalesContext);

interface Provider {
  children: React.ReactNode;
}

const SalesProvider: React.FC<Provider> = ({ children }) => {
  const isDevMode = window.location.origin.includes('localhost');
  const currentDomain = window.location.origin;
  const bffPort = '5001';
  const localHost = `${currentDomain.replace(/:\d+/, `:${bffPort}`)}`;
  const virtualHost = 'https://teste-bff.vercel.app';
  const bffBaseURL = `${!isDevMode ? localHost : virtualHost}/sales/convert-html-to-pdf`;

  const bffApiClient = axios.create({
    baseURL: bffBaseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  function convertHtmlToPdfHandler(
    url: string,
    filename: string,
    width?: number,
    height?: number,
    apiVersion = 'v2',
  ): AxiosPromise {
    const cleanFilename = filename.replace(/[&]/g, '_e_');
    return bffApiClient.get('/', {
      params: {
        url: `/api/${apiVersion}/html-to-pdf?url=${url}&filename=${cleanFilename}${width ? `&width=${width}` : ''}${
          height ? `&height=${height}` : ''
        }`,
      },
    });
  }

  return (
    <SalesContext.Provider
      value={{
        convertHtmlToPdfHandler,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesProvider;
